import React from "react"
import Layout from "../components/layout"
import { graphql, Link, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BannerInfo from "../components/bannerInfo"
import PassionStories from "../components/passionStories"
import SEO from "../components/seo"

const BannerData = () => {
  const data = [
    { head: "250+", description: "Scholarships till date" },
    {
      head: "100%",
      description:
        "Scholarship recipients who have gone to top colleges after graduation",
    },
    {
      head: "25%",
      description: "Current students receiving some financial aid",
    },
    {
      head: "35+",
      description: "Districts represented by scholarship recipients",
    },
  ]

  return (
    <div>
      {data.map((item, index) => (
        <BannerInfo
          head={item.head}
          text={item.description}
          key={index}
          odd={index % 2 === 1}
        />
      ))}
    </div>
  )
}

export default function () {
  const queryData = useStaticQuery(graphql`
    query {
      passionStories: allSanityPost(
        filter: { category: { title: { eq: "Scholarship" } } }
        sort: { fields: publishedAt, order: DESC }
        limit: 1
      ) {
        nodes {
          title
          slug {
            current
          }
          excerpt
          mainImage {
            asset {
              fluid {
                base64
                srcWebp
                srcSetWebp
                ...GatsbySanityImageFluid
              }
            }
          }
        }
        totalCount
      }
      Subekshya: file(relativePath: { eq: "subekshya.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            ...GatsbyImageSharpFluid
          }
        }
      }
      ScholarshipMain: file(relativePath: { eq: "scholarship-main.jpg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            ...GatsbyImageSharpFluid
          }
        }
      }
      SecondImage: file(relativePath: { eq: "students-in-hall.jpeg" }) {
        childImageSharp {
          fluid {
            aspectRatio
            base64
            src
            srcSet
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
    <SEO title="Scholarships"/>
      <section
        className="px-4 w-full lg:border-r lg:border-b border-gray-600 lg:text-center md:pb-20 lg:px-20 md:py-16"
      >
        <div className="section-for-small-devices">
          <Img
            className=""
            fluid={queryData.Subekshya.childImageSharp.fluid}
            alt="A young female student"
            draggable={false}
          />

          <h2 className="section-head mt-4 text-4xl xl:text-5xl">
            Quality education should be accessible to people from{" "}
            <span className="text-red-700">all economic backgrounds.</span>
          </h2>
        </div>
      </section>
      <section className="lg:border-b lg:border-l border-gray-600 ">
        <div className="section-for-small-devices">
          <h1 className="section-head text-left my-0 lg:text-center">
            About Our <span className="text-red-800">Scholarship</span>
          </h1>
          <div className="lg:flex mt-2 lg:mt-12">
            <div className="lg:w-1/2 lg:pr-8">
              <div className="section-image-fix">
                <Img
                  className="mt-4 lg:hidden"
                  fluid={queryData.ScholarshipMain.childImageSharp.fluid}
                  alt="Two Boy Students Standing"
                  draggable={false}
                />
              </div>
              <p className="mt-2">
                Bloom Nepal School provides merit-based financial aid to
                students from economically disadvantaged backgrounds. In some
                circumstances, like if you’re really dedicated but haven’t had
                the opportunity to show your academic abilities, we might be
                able to provide you financial aid irrespective of your prior
                academic record.
              </p>
              <p className="mt-4">
                All our scholarships are provided by{" "}
                <a href="https://bloomnf.org/" className="external-url">
                  Bloom&nbsp;Nepal&nbsp;Foundation
                </a>{" "}
                through the generosity of donors around the world.{" "}
              </p>
              <p className="mt-4 ">
                <a
                  href="https://bloomnf.org/gift-education"
                  className="external-url"
                >
                  Here is how you can support it too.
                </a>
              </p>
            </div>
            <div className="lg:w-1/2 lg-8">
              <Img
                className="mt-4 hidden lg:block"
                fluid={queryData.ScholarshipMain.childImageSharp.fluid}
                alt="Two Boy Students Standing"
                draggable={false}
              />
            </div>
          </div>
        </div>
      </section>
      <div className="">
        {queryData.passionStories.nodes.length > 0 ? (
          <div className="bg-transparent mt-16 lg:mt-24">
            <section className="m-0 p-0 bg-transparent">
              <h2 className="text-center section-head text-blue-800 lg:px-16">
                <span className="text-red-800">#scholar</span>stories
              </h2>
              <p className="lg:px-16 text-center mt-2 md:mt-4 lg:mb-6">
                Bloom Scholarship Receipents' Stories
              </p>
              <PassionStories
                data={queryData.passionStories}
                subtitle="scholar"
              />
            </section>
          </div>
        ) : (
          ""
        )}
      </div>
      <section className="lg:border-b lg:border-l border-gray-600">
        <div className="section-for-small-devices lg:flex lg:flex-row-reverse md:px-32 lg:px-0">
          <div className="flex-wrap lg:w-1/2 lg:pl-8">
            <BannerData />
          </div>
          <div className="lg:w-1/2 lg:pr-8 lg:flex lg:flex-col md:justify-center items-center">
            <h2 className="hidden lg:block section-head text-center">
              Be a <span className="text-red-700">Bloom </span> Nepal Scholar{" "}
            </h2>
            <p className="text-center mt-8">
              <div className=" hover:border-blue-900  md:block md:mt-8">
                <span className="border-b-2 border-red-900">
                  Learn How to Apply{" "}
                  <span className="font-semibold emo ">&darr;</span>
                </span>
              </div>
            </p>
          </div>
        </div>
      </section>
      <section id="apply" className="lg:border-b lg:border-r border-gray-600">
        <div className="section-for-small-devices">
          <h3 className="section-head">How To Apply</h3>
          <p className="mt-4 md:mt-8">
            First, make sure you qualify for a scholarship at Bloom. The
            requirements are listed below.
          </p>
          <ul className="mt-2 pl-4">
            <li className="list-disc">
              You have been studying at a community school for the last 3 years.
            </li>
            <li className="list-disc">You are the topper of your class.</li>
            <li className="list-disc">
              You and your parents are highly motivated.
            </li>{" "}
            <li className="list-disc">
              Your family cannot afford to pay for education at Bloom.
            </li>
          </ul>
          <p className="mt-4">
            If you qualify for all the requirements listed above, or you have a
            special story that you think might qualify you for a scholarship, we
            would like to hear from you. The following are the documents we
            would need.
          </p>
          <ul className="mt-2 pl-4 disc font-medium">
            <li>
              Most recent 2 years of academic Marksheet from your previous
              school.
            </li>
            <li>
              Your birth certificate (copy), Father’s or Mother’s citizenship
              card (copy).
            </li>
          </ul>
          <h4 className="section-head text-base mt-8 sm:mt-12 text-red-800">
            Submitting the Application
          </h4>
          <p className="mt-2">
            You may{" "}
            <a href="mailto:info@bloomn.edu.np" className="in-link">
              email us
            </a>{" "}
            the documents or have someone bring them to the school in Lalitpur.
            After reviewing your documents, we will contact you. If you’re
            selected in the first round, you will have to sit for an entrance
            exam. Then your admission will be finalized after we review your
            case more thoroughly.
          </p>
          <p className="mt-2">
            Anytime in the process, you may{" "}
            <Link to="/contact" className="in-link">
              contact us.
            </Link>{" "}
            Please feel free; we would love to guide you through.
          </p>
        </div>
      </section>
      <section className="pb-20 lg:border-b lg:border-l border-gray-600">
        <div className="section-for-small-devices lg:flex lg:items-center">
          <div className="hidden lg:block w-1/2 lg:pr-8">
            <Img
              className=""
              fluid={queryData.SecondImage.childImageSharp.fluid}
              alt="A female student doing her homework"
              draggable={false}
            />
            {/* <p className="caption">Muna says you hi!</p> */}
          </div>
          <div className="lg:w-1/2 lg:pl-8">
            <h3 className="section-head text-blue-900 text-left ">
              Request to Readers
            </h3>
            <div className="mt-6 lg:hidden section-image-fix">
              <Img
                className=""
                fluid={queryData.SecondImage.childImageSharp.fluid}
                alt="A female student doing her homework"
                draggable={false}
              />
            </div>
            <p className="mt-6">
              It’s a tragedy that the message about our scholarship often
              doesn’t reach those who need it the most and would qualify to
              receive it. If you come across someone who you think deserves our
              scholarship, we request you to connect them with us. Thank You.
            </p>
          </div>
        </div>
      </section>
    </Layout>
  )
}