import React, { useState } from "react"
import PassionStory from "./passionStory"

const PassionStories = ({
  data: { nodes: data },
  data: { totalCount },
  subtitle,
}) => {
  const defaultNumberOfPosts = 5
  const [numberOfStories, setNumberOfStories] = useState(defaultNumberOfPosts)
  return (
    <div className="mt-4 lg:mt-0">
      {data.map((post, index) => {
        if (index < numberOfStories) {
          return (
            <PassionStory
              data={post}
              key={index}
              order={index % 2}
              subtitle={subtitle}
            />
          )
        } else {
          return ""
        }
      })}
      <div className="text-center lg:-mt-16">
        {totalCount > defaultNumberOfPosts ? (
          <button
            onClick={event => {
              // display all stories
              if (numberOfStories === defaultNumberOfPosts) {
                setNumberOfStories(Infinity)
                event.target.innerText = "Show Less Stories"
              } else {
                setNumberOfStories(defaultNumberOfPosts)
                event.target.innerText = "More Bloom Stories"
              }
              // hide the button
              // event.target.parentNode.style = "visibility: hidden margin: 0 padding: 0 "
              // console.dir(event.target)
              // event.target.parentNode.remove()
            }}
            className="button-outline uppercase px-4 py-2 bg-blue-800 hover:bg-blue-900 text-white"
          >
            More Bloom Stories
          </button>
        ) : (
          ""
        )}
      </div>
    </div>
  )
}
export default PassionStories
