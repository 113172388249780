import React from "react"

export default function ({ odd, head, text }) {
  return (
    <div
      className={`flex-col align-center text-center bg-red-800 my-4 py-2 ${
        odd === true ? "bg-blue-900" : ""
      }`}
    >
      <h4 className="text-white font-medium text-4xl">{head}</h4>
      <p className="text-white text-base px-2">{text}</p>
    </div>
  )
}
