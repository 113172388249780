import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";

// import { getImage, GatsbyImage } from "gatsby-plugin-image"

const PassionStory = ({ data, order, subtitle }) => {
  return (
    <Link to={`/stories/${data.slug.current}`}>
      <div
        className={`${
          order === 0
            ? "lg:border-r lg:border-l-0"
            : "lg:border-l lg:border-r-0 md:flex-row-reverse"
        } border-gray-600 lg:border-b md:flex items-center lg:px-8 lg:py-16 md:mb-32  md:mt-16 mt-16 md:px-8 sm:px-12 px-8 md:pt-16 sm:py-12 py-8 md:bg-transparent bg-gray-100 md:hover:bg-gray-200  mx-auto max-w-full lg:border-t-0`}
      >
        <div className={`${order === 0 ? "md:pr-8" : "md:pl-8"} lg:w-1/2`}>
          <h2 className="text-2xl font-medium leading-tight md:text-left text-center">
            <span className="border-b-2 border-black inline-block ">
              {data.title}
            </span>
          </h2>
          <Img
            fluid={data.mainImage.asset.fluid}
            className="md:hidden mt-4"
            alt={data.title}
            draggable={false}
          />

          {/* <GatsbyImage
            fluid={data.mainImage.asset.childImageSharp.gatsbyImageData}
            className="md:hidden mt-4"
            alt={data.title}
            draggable={false}
          /> */}
          <p className="md:text-gray-800 md:mt-6 mt-4">{data.excerpt}</p>
        </div>
        <div className={`${order === 0 ? "md:pl-8" : "md:pr-8"} md:w-1/2`}>
          <Img
            fluid={data.mainImage.asset.fluid}
            className="hidden md:block"
            alt={data.title}
            draggable={false}
          />
          {/* {data.title} */}
          <h4 className="section-head text-xl text-blue-800 text-center lg:mt-4 mt-6">
            <span className="text-red-800">#{subtitle}</span>stories
          </h4>
        </div>
      </div>
    </Link>
  );
};

export default PassionStory;
